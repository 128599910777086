<template>
  <page-head-wrapper>
    <a-spin :spinning="loading">
      <div class="key-top-wrap">
        <div class="tit-wrap">应用名称：<span class="tit">{{detailData.applicationName || ''}}</span></div>
        <div>
          <a-button type="primary" @click="createCer">
            <IcKey style="margin-right: 4px" />
            创建密钥
          </a-button>
        </div>
      </div>
      <div class="mp-table-wrap">
        <a-table
          :columns="columns"
          :data-source="cerList"
          rowKey="certificateId"
          :pagination="false"
        >
          <div slot="certificateKey" slot-scope="text, record">
            SK:
            <span v-show="record.cerShow">{{text}}</span>
            <span v-show="!record.cerShow" style="color: #D8D8D8">••••••••••••••••••••••••••••••••</span>
            <a-button type="link" v-show="record.cerShow" @click="record.cerShow = false">隐藏</a-button>
            <a-button type="link" v-show="!record.cerShow" @click="record.cerShow = true">显示</a-button>
          </div>
          <div slot="certificateState" slot-scope="text">
            <a-tag color="#E9EDF2" v-if="text === 'y'">
              <a-badge color="#52C41A" text="启用" />
            </a-tag>
            <a-tag color="#E9EDF2" v-if="text === 'n'">
                <a-badge color="#FF3B30" text="禁用" />
            </a-tag>
          </div>
          <div slot="certificateMemo" slot-scope="text, record">
            <div v-show="!record.descInput" 
              style="height: 32px; line-height: 32px" 
              @click="inputMemoHandle(record)"
            >

            <span v-if="text">{{text}}</span>
            <span v-else style="color: #bfbfbf">请输入密钥说明</span>
            </div>
            <a-input :ref="'memoInput' + record.certificateId" v-show="record.descInput" v-model="record.certificateMemo"
              placeholder="请输入密钥说明"
              @blur="blurMemoHandle(record)" 
              @keyup.enter="enterMemoHandle(record)"
            />
          </div>
          <div slot="handles" slot-scope="text, record">
            <a-button v-if="record.certificateState === 'y'" type="link" size="small" @click="updateStateHandle(record, 'n')">禁用</a-button>
            <a-button v-if="record.certificateState === 'n'" type="link" size="small" @click="updateStateHandle(record, 'y')">启用</a-button>
            <a-button v-if="record.certificateState === 'n'" type="link" size="small" @click="deleteHandle(record)">删除</a-button>
          </div>
        </a-table>
      </div>
    </a-spin>
  </page-head-wrapper>
</template>

<script>
import {
	applicationDetail,
	certificateList, 
	certificateSave,
	certificateUpdateState,
	certificateUpdateMemo,
	certificateRemove
} from "@/api/app-permissions"
import {mapGetters} from 'vuex'
import IcKey from "@/assets/icons/ic_key.svg"
const columns = [
  {
    align: "center",
    title: "密钥",
    dataIndex: "certificateKey",
    scopedSlots: { customRender: "certificateKey" },
		width: '400px'
  },
  {
    align: "center",
    title: "创建时间",
    dataIndex: "certificateSubmitTime",
  },
  {
    align: "center",
    title: "状态",
    dataIndex: "certificateState",
    scopedSlots: { customRender: "certificateState" },
  },
  {
    align: "center",
    title: "描述说明",
    dataIndex: "certificateMemo",
    ellipsis: true,
		scopedSlots: { customRender: 'certificateMemo'},
    width: "20%",
  },
  {
    align: "left",
    title: "相关操作",
    scopedSlots: { customRender: "handles" },
  },
]
export default {
	computed: {
		...mapGetters(['adminId'])
	},
  components: {
    IcKey,
  },
  data() {
    return {
			loading: false,
      columns,
			detailData: {},
			cerList: []
    }
  },
	created() {
		this.getDetail()
		this.getCerList()
	},
  methods: {
    getDetail() {
			this.loading = true
			applicationDetail({
				applicationId: this.$route.query.id
			}).then(res => {
				console.log(res)
				if (res.code === 200) {
					this.detailData = res.data
				} else {
					this.$message.error(res.msg || '网络异常，请稍后重试')
				}
			}).catch(err => {
				console.warn(err)
			}).finally(() => {
				this.loading = false
			})
		},
		// 获取密钥列表
		getCerList() {
			certificateList({applicationId: this.$route.query.id}).then(res => {
				console.log(res)
				this.cerList = res.data.map(item => {
					return {
						...item,
						cerShow: false,
						descInput: false
					}
				})
			}).catch(err => {
				console.warn(err)
			})
		},
		// 创建密钥
		createCer() {
			if (this.cerList.length >= 2) {
				return this.$message.warning('一个应用最多只能创建2条密钥哟！')
			}
			this.$confirm({
        title: '确认创建密钥吗?',
        // content: h => <div>Some descriptions</div>,
				centered: true,
        onOk: () => {
          console.log('OK');
          certificateSave({
            applicationId: this.$route.query.id,
            certificateSubmitAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('创建成功')
							this.getCerList()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          })
        }
      })
		},
		// 更新状态
		updateStateHandle(record, state) {
			let handleText = state === 'y' ? '启用' : '禁用'
			this.$confirm({
        title: handleText + '该密钥吗?',
				centered: true,
        onOk: () => {
          console.log('OK');
          certificateUpdateState({
						certificateId: record.certificateId,
						certificateState: state,
						certificateLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success(handleText + '成功')
							this.getCerList()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          })
        }
      })
		},
		// 点击备注
		inputMemoHandle(record) {
			let ref = 'memoInput' + record.certificateId
			record.descInput = true
			this.$nextTick(() => {
				this.$refs[ref].focus()
			})
		},
		// 焦点离开备注输入框
		blurMemoHandle(record) {
			let ref = 'memoInput' + record.certificateId
			console.log(this.$refs[ref].value)
			record.descInput = false
			this.updateMemoHandle(record, this.$refs[ref].value)
		},
		enterMemoHandle(record) {
			let ref = 'memoInput' + record.certificateId
			console.log(this.$refs[ref].value)
			this.$nextTick(() => {
				this.$refs[ref].blur()
			})
		},
		// 更新备注
		updateMemoHandle(record, mome) {
			certificateUpdateMemo({
				certificateId: record.certificateId,
				certificateMemo: mome,
				certificateLastUpdateAdminId: this.adminId
			}).then(res=> {
				if (res.code === 200) {
					this.$message.success('密钥说明更新成功')
				} else {
					this.$message.error(res.msg || '网络异常，请稍后重试')
				}
			}).catch(err => {
				console.warn(err)
			})
		},
		// 删除密钥
		deleteHandle(record) {
			this.$confirm({
        title: '确认删除该密钥吗?',
				centered: true,
        onOk: () => {
          console.log('OK');
          certificateRemove({
						ids: record.certificateId,
						certificateLastUpdateAdminId: this.adminId
          }).then(res=> {
            if (res.code === 200) {
              this.$message.success('删除成功')
							this.getCerList()
            } else {
              this.$message.error(res.msg || '网络异常，请稍后重试')
            }
          }).catch(err => {
            console.warn(err)
          })
        }
      })
		},
  },
};
</script>

<style lang="less" scoped>
.key-top-wrap {
  padding: 16px 24px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  min-width: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .tit-wrap {
    font-size: 14px;
    color: #333;
    .tit {
      margin-left: 32px;
    }
  }
}
</style>